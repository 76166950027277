// await wait(30)
export const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

/**
 * Pick a list of properties from an object
 * into a new object
 */
export const pick = <T extends object, TKeys extends keyof T>(
  obj: T,
  keys: TKeys[]
): Pick<T, TKeys> => {
  if (!obj) return {} as Pick<T, TKeys>;
  return keys.reduce((acc, key) => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) acc[key] = obj[key];
    return acc;
  }, {} as Pick<T, TKeys>);
};

/**
 * Omit a list of properties from an object
 * returning a new object with the properties
 * that remain
 */
export const omit = <T, TKeys extends keyof T>(
  obj: T,
  keys: TKeys[]
): Omit<T, TKeys> => {
  if (!obj) return {} as Omit<T, TKeys>;
  if (!keys || keys.length === 0) return obj as Omit<T, TKeys>;
  return keys.reduce(
    (acc, key) => {
      // Gross, I know, it's mutating the object, but we
      // are allowing it in this very limited scope due
      // to the performance implications of an omit func.
      // Not a pattern or practice to use elsewhere.
      delete acc[key];
      return acc;
    },
    { ...obj }
  );
};

/**
 * Get a random item from an array
 */
export const sample = <T>(arr: T[]): T =>
  arr[Math.floor(Math.random() * arr.length)];

/**
 * Get item that appears most from an array
 */
export const mostCommonItem = <T>(arr: T[]): T | undefined =>
  [...arr]
    .sort(
      (a, b) =>
        arr.filter((it) => it === a).length -
        arr.filter((it) => it === b).length
    )
    .pop();
/**
 * Humanize a string
 * @example
 * humanize('helloWorld') // Hello World
 */
export const humanize = (str: string) =>
  str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (s) => s.toUpperCase())
    .trim();

/**
 * Sleep for a given number of milliseconds
 */
export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
