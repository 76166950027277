<template>
  <table :class="$style.table">
    <thead>
      <tr>
        <th
          v-if="withSelection"
          style="width: 0"
          @click.stop="!disableSortSelected && onSort('isSelected')"
          :class="[!disableSortSelected && hasSorting && $style.sortable]"
        >
          <div class="flexAlign gap-xs">
            <span
              v-if="hasSorting"
              :data-i="!disableSortSelected && sortIcon('isSelected')"
              :class="{
                [$style.isDesc]: sortBy === 'isSelected' && isDescending,
              }"
              style="--offsetRight: 0"
            />
          </div>
        </th>
        <th
          v-for="header in headers"
          :key="header.value"
          :style="header.style"
          :class="[
            header.class,
            !header.disableSort && hasSorting && $style.sortable,
          ]"
          @click.stop="!header.disableSort && onSort(header.value)"
        >
          <div class="flexAlign gap-xs" v-tooltip="header.tooltip">
            {{ header.text }}
            <span
              v-if="hasSorting"
              :data-i="!header.disableSort && sortIcon(header.value)"
              :class="{
                [$style.isDesc]: sortBy === header.value && isDescending,
              }"
              style="--offsetRight: 0"
            />
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="row in sortedRows"
        :key="row.id"
        :data-row-id="row.id"
        @click.stop="$emit('onSelected', !row.isSelected, row)"
        :class="{ [$style.selected]: row.isSelected }"
      >
        <td v-if="withSelection">
          <div class="flexAlign">
            <input
              :id="row.id"
              type="checkbox"
              data-cy="checkbox-row-select"
              :checked="Boolean(row.isSelected)"
              :indeterminate.prop="row.isSelected === null"
              :class="$style.selectionCheckbox"
            />
          </div>
        </td>
        <td
          v-for="header in headers"
          :key="header.value"
          :style="header.style"
          :class="header.class"
        >
          <slot :name="header.value" :row="row" :value="row[header.value]">
            {{ row[header.value] }}
          </slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script
  setup
  lang="ts"
  generic="T extends Record<string, string | number>, K extends keyof T"
>
import { watch } from 'vue';
import { computed } from 'vue';
import { ref } from 'vue';
defineEmits(['onSelected']);
const props = defineProps<{
  withSelection?: boolean;
  withSorting?: boolean;
  disableSortSelected?: boolean;
  initialSortBy?: K;
  initialIsDescending?: boolean;
  rows: (T & { id: string; isSelected?: boolean })[];
  headers: {
    text: string;
    value: K;
    class?: string;
    style?: string;
    disableSort?: boolean;
    tooltip?: string;
  }[];
}>();

const sortBy = ref<K | 'isSelected'>();
const isDescending = ref(props.initialIsDescending);

watch(
  () => props.initialSortBy,
  (initialSortBy) => (sortBy.value = initialSortBy),
  { immediate: true }
);

const sortedRows = computed(() => {
  if (!sortBy.value) return props.rows;

  return props.rows.slice().sort((a, b) => {
    const key = sortBy.value;
    const sortSgn = isDescending.value ? -1 : 1;
    if (!key) return 0;
    if (typeof a[key] === 'string' && typeof b[key] === 'string') {
      return (
        sortSgn *
        (a[key] as string).localeCompare(b[key] as string, undefined, {
          numeric: true,
        })
      );
    }

    return sortSgn * (Number(a[key]) - Number(b[key]));
  });
});

const hasSorting = computed(() => props.withSorting && props.rows.length > 1);

function sortIcon(val: K | 'isSelected') {
  if (val !== sortBy.value) return 'swap_vert';
  return 'straight';
}

function onSort(val: K | 'isSelected') {
  if (!hasSorting.value) return;
  if (val === sortBy.value) {
    isDescending.value = !isDescending.value;
  }

  sortBy.value = val;
}
</script>
<style module>
.table {
  --tableFontSize: 0.75rem;
  --borderColor: var(--gray-5);
  --focusBackground: var(--blue-1);
  --theadBackground: var(--gray-3);
  --tbodyBackground: transparent;
  --primaryColor: var(--color-signal);
  --highlightBackground: var(--gold-1);

  --defaultPadding: 0.5rem 0.25rem;
  --thPadding: var(--defaultPadding);
  --tdPadding: var(--defaultPadding);

  width: 100%;
  font-size: 0.75rem;
  border-collapse: collapse;
  text-align: center;

  & thead {
    background-color: var(--theadBackground);
  }
  & tbody {
    background-color: var(--tbodyBackground);
  }

  & th {
    padding: var(--thPadding);
    padding-right: 0;
    font-weight: 500;
    &:not(:last-child) > div {
      padding-right: 0.25rem;
      border-right: 1px solid var(--borderColor);
      justify-content: var(--justifyContent, center);
    }
  }

  & td {
    padding: var(--tdPadding);
    border-bottom: 1px solid var(--borderColor);

    &:first-of-type {
      padding-left: 0.5rem;
    }
    &:last-of-type {
      padding-right: 0.5rem;
    }
  }

  & tbody tr:has(input.selectionCheckbox):hover,
  & tbody tr:has(input.selectionCheckbox:checked),
  & tbody tr.selected {
    background: var(--highlightBackground);
  }

  & tbody tr:focus-within:has(input) {
    background: var(--focusBackground);
  }

  & tbody tr:has(input.selectionCheckbox:checked) {
    box-shadow: 2px 0 0 0 inset var(--primaryColor);
  }
}

.isDesc {
  transform: scaleY(-1);
}

.sortable {
  cursor: pointer;
}
</style>
