<template>
  <div
    :class="`formulate-input-element formulate-input-element--amount`"
    :data-type="context.type"
  >
    <FormulateInput
      type="number"
      v-model="value"
      v-bind="context.attributes"
      :class="context.classes.input"
      @blur="context.blurHandler"
    />
    <div class="formulate-input-element__percentage-symbol"></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ToBeFixed } from '@cutr/constants/utility-types';

type Props = {
  context: ToBeFixed;
};

const props = defineProps<Props>();

const value = computed({
  get() {
    return props.context.model || 0;
  },
  set(newValue) {
    // eslint-disable-next-line vue/no-mutating-props
    props.context.model = newValue;
  },
});
</script>
