import {
  useApi,
  useFeatureFlags,
  useToast,
  useTrack,
  useTranslation,
} from '@/composables/plugins';
import type { Order } from '@/types/order';
import { useActiveUserStore } from '@/modules/store';
import { computed, ref } from 'vue';

export const useDraftOrder = () => {
  const api = useApi();
  const track = useTrack();
  const toast = useToast();
  const feature = useFeatureFlags();
  const store = useActiveUserStore();
  const { t } = useTranslation();

  const order = ref<Order | null>();
  const variantId = computed<string | undefined>(
    () => order.value?.orderVariants[0]?.id
  );

  async function beforeCreate(isCutlist = false, cutlistId?: string) {
    try {
      await createUnauthUser();
      // TODO: pass cutlistID down the hole here
      order.value = await createDraftOrder(isCutlist, cutlistId);
    } catch (err) {
      console.error(err);
      toast.error(t('landing-page:errors.orderCreation'));
      track('order:error', { event: 'create order', err });
    }
  }

  const createDraftOrder = (isCutlist: boolean, cutlistId?: string) => {
    const query = cutlistId
      ? `?isCutlist=true&cutlistId=${cutlistId}`
      : isCutlist
      ? '?isCutlist=true'
      : '';
    return api.post(`/v2/orders${query}`).then(({ data }) => data.data);
  };

  const createUnauthUser = async () => {
    if (!store.activeUserType || store.activeUserType == 'Public') {
      await api.post('/v2/sessions/user').then((resp) => {
        store.setActiveUser(resp.data.data);
        return feature.refreshFeatureFlags();
      });
    }
  };

  return { order, variantId, beforeCreate };
};
