<template>
  <div class="costdoc_nav_bar">
    <div class="costcostdoc_nav_items">
      <div
        v-for="tab in options"
        :key="tab.key || tab.value"
        :class="{
          [$style.selected]: value === tab.value,
          [$style.navItem]: true,
        }"
        @click="
          () => {
            $track('tabnav-click', tab);
            $emit('input', tab.value);
          }
        "
      >
        <div
          :class="{
            [$style.navItemLabel]: true,
          }"
          :data-cy="tab.datacy"
          role="button"
        >
          <div class="flexAlign">
            <Icon v-if="tab.icon" :icon="tab.icon" style="--offsetRight: 0" />
            <span>{{ tab.label }}</span>
            <Badge
              class="pill text-small"
              v-if="tab.quoteStatus && !store.isMaker"
              v-bind="
                getStatusBadge({
                  status: tab.quoteStatus,
                  userType: store.activeUserType,
                })
              "
            />
          </div>
        </div>
        <div v-if="tab.description" :class="$style.description">
          {{ tab.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getStatusBadge } from '@/helpers/quote-helpers';
import { useActiveUserStore } from '@/modules/store';

defineProps({
  options: {
    type: Array,
    required: true,
  },
  value: {
    type: String,
  },
});

const store = useActiveUserStore();
</script>

<style module>
.navItem {
  cursor: pointer;
  font-size: 1rem;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--navBarPadding);
  font-weight: 400;
  --colorHighlighted: var(--colorTabHighlighted, var(--color-signal));

  &:hover {
    color: var(--colorHighlighted);
  }

  &.selected {
    color: var(--colorHighlighted);
    border-bottom-color: var(--colorHighlighted);
    font-weight: 500;
  }

  /* compensate for bold thickness shifting the element by making it slightly bigger */
  &:not(.selected) {
    padding-right: 2px;
  }
}
.navItemLabel {
  text-decoration: none;
  white-space: nowrap;
  margin-bottom: var(--space-s);

  .icon {
    font-size: 18px;
    padding-right: 0.2em;

    &.replaced,
    &.missed,
    &.expired,
    &.declined,
    &.feedback,
    &.replaced {
      display: none;
    }
  }
}
.description {
  font-weight: 400;
  color: var(--gray-7);
  margin-top: 0.5rem;
}
</style>
