import baseURL from '@/helpers/base-url';
import { ToBeFixed } from '@cutr/constants/utility-types';

type LiveEvent<T> = {
  type: string;
  payload: T;
};

const EVENT_TYPES: Record<string, ((data: LiveEvent<ToBeFixed>) => void)[]> = {
  new_conversation_message: [
    (data) => {
      console.log('new_conversation_message', data);
    },
  ],
  new_notification: [],
};

function handleEvent(event: MessageEvent) {
  const data = JSON.parse(event.data);
  if (!EVENT_TYPES[data.type]) {
    console.log('unknown event type', data);
    return;
  }

  EVENT_TYPES[data.type].forEach((fn) => fn(data));
}

export const liveUpdates = {
  source: null as EventSource | null,
  connect() {
    this.source = new EventSource(`${baseURL}/v2/sessions/live-updates`, {
      withCredentials: true,
    });
    this.source.addEventListener('message', handleEvent);
    this.source.addEventListener('error', (e) => {
      console.error('closing connection', e);
    });
    console.log('connected');
  },
  disconnect() {
    this.source?.close();
  },
  handleEvent<T = unknown>(event: string, fn: (data: LiveEvent<T>) => void) {
    if (!EVENT_TYPES[event]) {
      EVENT_TYPES[event] = [];
    }

    EVENT_TYPES[event].push(fn);
    // unsubscribe
    return () => {
      EVENT_TYPES[event] = EVENT_TYPES[event].filter((f) => f === fn);
    };
  },
};
