import { computed, ref } from 'vue';
import { track } from '../helpers/tracker';
import i18next from '../helpers/i18n.ts';
import { defineStore, acceptHMRUpdate } from 'pinia';
import * as Sentry from '@sentry/vue';
import api from '@/helpers/api';

export const useActiveTaskStore = defineStore('activeTasks', () => {
  let timeout;
  const task = ref({ progress: 0, label: '' });
  const activeTask = computed(() => task.value || {});
  function setActiveTask(taskUpdate) {
    if (timeout) clearTimeout(timeout);
    taskUpdate.progress = Math.round(taskUpdate.progress || 0);
    task.value = taskUpdate;

    if (task.value.progress == 100) {
      timeout = setTimeout(() => {
        task.value = {};
      }, 2000);
    }
  }

  return { task, activeTask, setActiveTask };
});

export const useActiveUserStore = defineStore('activeUser', {
  state: () => ({
    /** @type {import('@cutr/constants/order').User} User */
    user: {
      id: '',
      _id: 'public',
      type: 'Public',
      name: undefined,
      email: '',
      organisationId: '',
      customerOrganisationId: '',
      linkedAccountUserId: '',
      cutlistAdminSourceId: undefined,
    },
    userSettings: {},
  }),
  getters: {
    activeUser: (state) => state.user,
    activeUserId: (state) => state.user.id,
    activeUserType: (state) => state.user.type,
    superUserId: (state) => state.user.superUserId,
    activeUsername: (state) => state.user.name || '',
    organisationId: (state) => state.user.organisationId,
    customerOrganisationId: (state) => state.user.customerOrganisationId,
    userCutlistAdminSourceId: (state) => state.user.cutlistAdminSourceId,
    activeUserSettings: (state) => state.userSettings || {},
    isAdmin: (state) => ['Admin'].includes(state.user.type),
    isMaker: (state) => ['Maker'].includes(state.user.type),
    isCustomer: (state) => ['Customer'].includes(state.user.type),
  },
  actions: {
    setActiveUser(user) {
      if (user._id == this.user._id) return;

      this.user = user;
      this.userSettings = {
        viewerActive: true,
        visualDebug: false,
      };

      Sentry.setUser(user);
      track('identify', user);

      const params = new URLSearchParams(window.location.search);
      if ((navigator.userAgent || '').startsWith('e2e-test')) {
        i18next.changeLanguage('en');
        return;
      }
      // ignore user locale if lng param is set. TODO: this should probably be in language detector
      if (user.locale && !params.get('lng')) {
        i18next.changeLanguage(user.locale);
      }
    },
    updateActiveUser(user) {
      this.user = { ...this.user, ...user };
    },
    setActiveUserToPublic() {
      this.user = { _id: 'public', type: 'Public' };
    },
    async switchRole() {
      if (!this.user.customerOrganisationId) {
        await api.post(
          `/v2/organisation/${this.organisationId}/customer-organisation`
        );
      }

      return api
        .post('/v2/sessions/toggle-organization')
        .then(() => location.reload());
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useActiveUserStore, import.meta.hot));
  import.meta.hot.accept(acceptHMRUpdate(useActiveTaskStore, import.meta.hot));
}
