<template>
  <div :class="$style.wrapper">
    <header class="flexAlign" :class="$style.header">
      <h2>
        {{ $t('notifications:title') }}
      </h2>
      <Button
        v-if="notificationsStore.unreadCount"
        class="sml white fluid-width"
        style="margin-left: auto"
        icon="mark_email_read"
        @click="notificationsStore.markAllRead()"
      >
        {{ $t('notifications:markAllRead') }}
        {{ `(${notificationsStore.unreadCount})` }}
      </Button>
    </header>
    <ul v-if="notificationsList.length">
      <li
        v-for="notification in notificationsList"
        :key="notification.id"
        :class="{ [$style.unread]: !notification.readAt }"
        @click="
          () => {
            markRead(notification);
            notification.onClick();
          }
        "
      >
        <header :class="$style.notificationHeader">
          <div>
            <strong
              >{{ notification.order.shortId }} -
              {{ notification.title }}</strong
            >
          </div>
          <time
            :class="$style.notificationTime"
            :title="$format.date(notification.createdAt, 'full', 'long')"
            >{{ $format.relativeTime(notification.createdAt) }}</time
          >
        </header>
        <p>{{ notification.message }}</p>
      </li>
    </ul>
    <div v-else>
      <p style="padding: 0 1rem">{{ $t('notifications:empty') }}</p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router/composables';
import { Notification, NotificationType } from '@cutr/constants/notifications';
import { useNotifications } from '@/elements/Notifications/store';
import { computed } from 'vue';
import { useTranslation } from '@/composables/plugins';

defineProps<{ withCounter?: boolean }>();

const notificationsStore = useNotifications();
const router = useRouter();
const { t } = useTranslation();

const NOTIFICATION_GENERATORS: Record<
  NotificationType,
  (n: Notification) => { title: string; message: string; onClick: () => void }
> = {
  new_conversation_message: (n) => ({
    title: t(`notifications:newConversationMessage.title`),
    message: `${n.data?.author?.name || 'Unknown'}: ${truncate(
      n.data?.message,
      120
    )}`,
    onClick: () =>
      router.push({
        name: 'order:messages',
        params: { id: n.order.id },
        query: { notificationId: n.id },
      }),
  }),

  offer_awarded: (n) => ({
    title: t(`notifications:offerAwarded.title`),
    message: t(`notifications:offerAwarded.message`),
    onClick: () => router.push({ name: 'order', params: { id: n.order.id } }),
  }),

  offer_available: (n) => ({
    title: t(`notifications:offerAvailable.title`),
    message: t(`notifications:offerAvailable.message`),
    onClick: () =>
      router.push({
        name: 'order:quotes',
        params: { id: n.order.id },
        query: { proposalId: n.resourceId },
      }),
  }),

  offer_missed: (n) => ({
    title: t(`notifications:offerMissed.title`),
    message: t(`notifications:offerMissed.message`),
    onClick: () => {},
  }),

  nesting_jobs_completed: (n) => ({
    title: t('notifications:nestingJobsCompleted.title'),
    message: t('notifications:nestingJobsCompleted.message', {
      shortOrderId: n.order.shortId,
    }),
    onClick: () => {
      router.push({
        name: 'order:material-assignment',
        params: { id: n.order.id },
        query: {
          variantId: n.resourceId,
          openNesting: 'true',
        },
      });
    },
  }),
};

const notificationsList = computed(() =>
  notificationsStore.notifications.map((n) => ({
    ...n,
    ...NOTIFICATION_GENERATORS[n.type](n),
  }))
);

function markRead({ id, readAt }: Notification) {
  if (readAt) return;
  notificationsStore.markRead({ id: [id] });
}

function truncate(text: string, length: number) {
  if (text?.length > length) {
    return text.substring(0, length) + '...';
  }
  return text;
}
</script>
<style module>
.wrapper {
  display: grid;

  & h2 {
    padding: 1rem;
    margin: 0;
  }

  & ul {
    padding: 0;
    margin: 0;
    font-size: 0.875rem;
  }

  & li {
    list-style: none;
    padding: 1rem 1rem 0.5rem;
    position: relative;
    border-bottom: 1px solid var(--gray-4);
  }

  & p {
    margin: 0.5rem 0;
  }

  & li:hover {
    background-color: var(--gray-2);
    cursor: pointer;
  }
}

.counter {
  border-radius: 100%;
  width: 1.2rem;
  height: 1.2rem;
  background-color: var(--color-error);
  color: var(--gray-1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border: 1px solid var(--gray-1);
}

.unread {
  background-color: var(--gold-1);
}

.unread .notificationTime:after {
  content: '';
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  background-color: var(--color-signal);
  margin-left: 0.5rem;
}

.header {
  top: 0;
  z-index: 1;
  position: sticky;
  background-color: var(--gray-1);
  border-bottom: 1px solid var(--gray-4);
}

.notificationHeader {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.notificationTime {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  color: var(--gray-7);
}
</style>
