import { useTranslation } from '@/composables/plugins';
import { DestroyUserSession } from '@/helpers/session-helpers';
import { useActiveUserStore } from '@/modules/store';
import { useRouter } from 'vue-router/composables';
import { nav, pathIsAllowed } from '@/modules/routing';
import { computed } from 'vue';
import { DropdownMenuButton } from '@/components/common/types';

export function useNavigation() {
  const store = useActiveUserStore();
  const router = useRouter();
  const { t } = useTranslation();

  const goToRoute = function (name: string) {
    if (['settings', 'signin', 'signup'].includes(name)) {
      router.push({ name }).catch((err) => console.log(err.message));
      return;
    }

    if (name === 'signout') {
      // Destroy session then reroute to signin.
      DestroyUserSession(store, () => {
        router.push({ name: 'home' }).catch((err) => {
          console.log(err.message);
        });
      });
    }
  };

  const navLinks = computed(() => {
    const userType = store.activeUserType || 'Public';

    return nav.filter(({ name, show }) => {
      if (show && show(store) !== true) return false;

      return pathIsAllowed(userType, name);
    });
  });

  const accountMenuLinks = computed(() => {
    const signOutText = store.superUserId
      ? t('navigation:menu.backToAdmin')
      : t('navigation:menu.signOut');

    return (
      [
        {
          icon: 'person',
          label: t('navigation:menu.account'),
          to: { name: 'settings' },
          on: (hide) => ({ click: hide }),
        },

        store.isAdmin && {
          icon: 'settings',
          label: 'Platform Config',
          to: { name: 'admin:config' },
          on: (hide) => ({ click: hide }),
        },
        {
          icon: 'logout',
          class: 'alert',
          label: signOutText,
          on: (hide) => ({
            click() {
              hide();
              goToRoute('signout');
            },
          }),
        },
      ] as DropdownMenuButton[]
    ).filter(Boolean);
  });

  const isSignedIn = computed(
    () =>
      store.activeUserType &&
      !['Public', 'Unauthenticated'].includes(store.activeUserType)
  );

  return { goToRoute, navLinks, accountMenuLinks, isSignedIn };
}
