<template>
  <div>
    <h3>{{ $t('landing-page:project.design-files.title') }}</h3>

    <FileUploaderOrderIntake
      :name="'launchpad-file-upload'"
      :label="$t('landing-page:fileInputLabel')"
      :accept="supportedFileExtensionsCommaSeparated"
      :handleSingleFile="handleSingleFile"
      :afterFileUpload="afterFileUpload"
      :beforeFileUpload="beforeCreate"
      :fileTypeLabels="FILE_EXTENSIONS"
      class="buffer-bottom-lg buffer-top"
      tid="page:launchpad:upload-file"
      :multiple="true"
    />

    <IpNotice
      @click="
        () =>
          $track('page:launchpad:privacy-policy', { quoteType: 'design-files' })
      "
    />

    <hr />

    <h4>
      <strong>{{ $t('landing-page:howItWorks.title') }}</strong>
    </h4>

    <div class="flexAlign buffer-top">
      <Button
        class="pill white fluid-width"
        icon="play_circle"
        :href="getDemoVideoUrl($i18next.resolvedLanguage)"
        target="_blank"
        :iconBefore="true"
        :trackOverride="
          () =>
            $track('page:launchpad:video-button', { quoteType: 'design-files' })
        "
      >
        {{ $t('landing-page:howItWorks.video') }}
      </Button>
      <DownloadButton
        class="pill white fluid-width"
        :trackOverride="
          () => $track('page:launchpad:download-sample-file-button', {})
        "
        type="exampleFile"
        :resource="{}"
        icon-before
      >
        {{ $t('landing-page:howItWorks.downloadSample') }}
      </DownloadButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import {
  SUPPORTED_FILE_EXTENSIONS,
  FILE_EXTENSIONS,
} from '@/helpers/file-helpers';
import FileUploaderOrderIntake from '../FileUploaderOrderIntake.vue';
import {
  useApi,
  useRouter,
  useToast,
  useTrack,
  useTranslation,
} from '@/composables/plugins';
import * as Sentry from '@sentry/vue';
import DownloadButton from '../DownloadButton.vue';
import IpNotice from '@/components/common/IpNotice.vue';
import { useDraftOrder } from './composables';
import { CadFile } from '@cutr/constants/order';
import { ref } from 'vue';
import { toastMessageFromAxiosError } from '@/helpers/api';

const api = useApi();
const track = useTrack();
const toast = useToast();
const router = useRouter();
const { t } = useTranslation();

const { order, variantId, beforeCreate } = useDraftOrder();
const fileId = ref('');

async function afterFileUpload(filetype: string, source: string) {
  track('order:cadfile-upload', { filetype, source, order: order.value });
  await router.push({
    name: `order:files`,
    params: { id: order.value?.id || '' },
    query: {
      variantId: variantId.value,
      fileId: fileId.value,
    },
  });
}

async function handleSingleFile(
  newFile: string | Blob,
  progress: (progress: number) => void,
  handleError: (error: string) => void
) {
  let result;
  try {
    track('page:launchpad:upload-started', { ...order.value } || {});
    result = await postSingleFile({
      newFile,
      progress,
      activeVariantId: variantId.value || null,
    });
    track('page:launchpad:upload-finished', { ...order.value } || {});
  } catch (err) {
    handleError(err as string);
    throw err;
  }
  return result;
}

async function postSingleFile({
  newFile,
  progress,
  activeVariantId,
}: {
  newFile: string | Blob;
  progress?: (progress: number) => void;
  activeVariantId: string | null;
}) {
  const formData = new FormData();
  formData.append('orderVariantId', activeVariantId || '');
  formData.append('assetType', 'design-file');
  formData.append('orderFile', newFile);
  let result;
  try {
    const { data } = await api.post('v2/order-files', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (xhr) => progress?.((xhr.loaded / xhr.total) * 100),
    });
    const file = data.data as CadFile;
    fileId.value = file.id;
    result = data;
  } catch (err) {
    track('order:error', {
      event: 'cadfile-upload',
      err,
      order: order.value,
    });
    Sentry.captureException(err);
    toast.error(
      t('landing-page:errors.cadFile', {
        message: toastMessageFromAxiosError(err),
      })
    );
    throw err;
  }
  return result;
}

const supportedFileExtensionsCommaSeparated = computed(() =>
  SUPPORTED_FILE_EXTENSIONS().join()
);

function getDemoVideoUrl(lng: string) {
  const id = lng === 'nl' ? 'd9ev52e3Ig0' : 'tTnV1c9BAa4';

  return `https://www.youtube.com/watch?v=${id}`;
}
</script>
