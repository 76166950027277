export const variants = [
  'warning',
  'secondary',
  'light',
  'grey',
  'blue',
  'transparent',
  'muted',
];

export const propsDefinition = {
  state: {
    type: String,
    validator: (value: string) => ['active', 'focus'].includes(value),
  },
  variant: {
    type: String,
    default: 'light',
    validator: (value: string) => variants.includes(value),
  },
  onClick: Function,
  icon: String,
  tid: String,
  noBorder: Boolean,
};
