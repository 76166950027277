import Debug from 'debug';
import { reactive } from 'vue';
import { track } from './tracker';
const debug = Debug('modal-manager');
const state = reactive({
  modals: new Map(),
  openModal: null,
  context: null,
});

export const modalManager = {
  isOpen(name) {
    return name === state.openModal;
  },

  /**
   *
   * @param {string} name
   * @param {Object} context
   */
  open(name, context = null) {
    assertValidModalName(name);
    debug(`Opening modal ${name}`, state);
    document.body.classList.add('body_lock');
    state.openModal = name;
    state.context = context;
    track('modal:open', { name });
  },

  get current() {
    return state.modals.get(state.openModal) || null;
  },

  get context() {
    return state.context;
  },
  set context(context) {
    state.context = context;
  },

  close(name, { trigger } = {}) {
    if (name !== 'any') assertValidModalName(name);

    if (name !== 'any' && name !== state.openModal) return;

    const closingModal = name === 'any' ? state.openModal : name;
    if (closingModal) {
      // TODO: tracking which modal closed when global
      track('modal:close', {
        name: closingModal,
        trigger,
      });
    }

    document.body.classList.remove('body_lock');

    if (!state.openModal) return;
    debug(`Closing modal ${state.openModal}`, state);
    state.openModal = null;
    state.context = null;
  },

  register(name, modal) {
    if (!name) throw new Error('Modal name is required for registering');
    debug(`Registering modal ${name}`, state);
    if (state.modals.has(name)) {
      debug(`Duplicate registation detected for modal ${name}`, state);
      return;
    }
    state.modals.set(name, modal);
  },

  deregister(name) {
    debug(`Deregistering modal ${name}`, state);
    if (name === state.openModal) {
      this.close(name, { trigger: 'deregistration' });
    }
    if (!state.modals.has(name)) return;
    state.modals.delete(name);
  },
};

function assertValidModalName(name) {
  if (typeof name !== 'string') {
    throw new Error('Modal name should be a string');
  }
  if (!state.modals.has(name)) {
    throw new Error(`Unknown modal ${name}`);
  }
}

export default {
  install(Vue) {
    debug('Installing modal manager');
    Vue.prototype.$modal = modalManager;
  },
};

export const propsDefinition = {
  name: {
    type: String,
    required: true,
  },
  contentClass: {
    type: String,
  },
  disableClose: {
    type: Boolean,
  },
  disableFocus: {
    default: true,
    type: Boolean,
  },
  wide: {
    type: Boolean,
  },
  mediumWide: {
    type: Boolean,
  },
  inline: {
    type: Boolean,
  },
};
