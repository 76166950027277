import { format } from './format-plugin';
import i18next from './i18n';
import { isOverdue } from './invoice-helpers';

const allowedQuoteActions = {
  draft: [
    { action: 'ready', icon: 'done' },
    { action: 'delete', icon: 'delete' },
  ],
  ready: [
    { action: 'accept', icon: 'done_all' },
    { action: 'askApproval', icon: 'published_with_changes' },
    { action: 'draft', icon: 'edit_note' },
    { action: 'expire', icon: 'remove_circle_outline' },
    { action: 'decline', icon: 'highlight_off' },
    { action: 'feedback', icon: 'call_missed' },
  ],
  expired: [
    { action: 'revive', icon: 'auto_fix_high_outline' },
    { action: 'update-request', icon: 'edit_note' },
  ],
  accepted: [],
  replaced: [],
  declined: [],
  feedback: [],
  updateRequested: [],
};

const DEFAULT_BADGE_OPTIONS = (status) => ({
  variant: 'secondary',
  label: `costdoc:status.${status}`,
});

const STATUS_BADGE_FOR_USER = {
  Any: {
    // Shared
    accepted: { icon: 'done_all', variant: 'success' },
    expired: { icon: 'remove_circle_outline' },
    paid: { icon: 'done_all', variant: 'success' },
    open: { icon: 'done', variant: 'default' },
    pastDue: { icon: 'schedule', variant: 'warning' },
    void: { icon: 'remove_circle_outline' },
    draft: { icon: 'edit_note' },
    updateRequested: { icon: 'edit_note', variant: 'primary' },
    pending: { icon: 'schedule', variant: 'default' },

    // Quote
    replaced: { icon: 'remove_circle_outline' },
    ready: { icon: 'done', variant: 'default' },

    // Quote Estimate
    saved: { icon: 'save', variant: 'primary' },

    // Offer
    dispatched: { icon: 'send', variant: 'primary' },

    // Dispatch
    missed: { icon: 'call_missed', variant: 'warning' },
    feedback: {
      icon: 'forum',
      variant: 'primary',
    },
  },

  Maker: {
    // Dispatch
    available: { icon: 'done', variant: 'primary' },
    declined: { icon: 'highlight_off', variant: 'secondary' },
  },

  Admin: {
    // Dispatch
    available: { icon: 'schedule', variant: 'default', label: 'Waiting' },
    declined: { icon: 'highlight_off', variant: 'alert' },
  },
};

export function getStatusBadge({ status, dueDate = null, userType }) {
  if (!userType) throw new Error('User type required');

  if (status === 'open' && isOverdue({ dueDate })) {
    status = 'pastDue';
  }

  const badge = {
    ...DEFAULT_BADGE_OPTIONS(status),
    ...(STATUS_BADGE_FOR_USER[userType]?.[status] ||
      STATUS_BADGE_FOR_USER.Any?.[status]),
  };

  badge.label = i18next.exists(badge.label)
    ? i18next.t(badge.label)
    : badge.label;

  return badge;
}

const CreatePaymentTerms = function ({
  costdoc,
  invoices = [],
  sumByProp = 'totalExAmount',
}) {
  const total = costdoc.totalExAmount;
  const filteredInvoices = filterInvoices(invoices).filter((it) =>
    it.category ? it.category === 'maker' : true
  );
  const totalInvoiced = filteredInvoices.reduce(
    (sum, item) => sum + (item[sumByProp] || 0),
    0
  );

  const remainingPercentage = 99.1 - (totalInvoiced / total) * 100;

  const terms = [];
  let option = 1;

  while (option < remainingPercentage) {
    terms.unshift(Math.round(option));
    option += 1;
  }
  terms.unshift('remainder');

  return terms;
};

const filterInvoices = function (invoices) {
  return invoices.filter((it) => it.status !== 'void');
};

const InvoiceFromCostDoc = function ({
  offer = undefined,
  quote = undefined,
  invoices = [],
  percentage = undefined,
  sumByProp = 'totalExAmount',
}) {
  if (offer && quote) {
    throw new TypeError('Pass either offer or quote not both');
  }

  const costdoc = offer || quote;

  const newInvoice = {
    // currency: quote.currency,
    items: [...costdoc.items],
    shortOrderId: costdoc.shortOrderId,
    currency: costdoc.currency,
    taxRate: costdoc.taxRate,
    status: 'draft',
    createdAt: Date.now(),
  }; // api will overwrite date with stripe actual

  const filteredInvoices = filterInvoices(invoices);

  if (!percentage) {
    if (filteredInvoices.length === 0) {
      percentage = offer ? 70 : 80;
    } else {
      percentage = 'remainder';
    }
  }

  const totalExAmount = costdoc.totalExAmount;
  const totalInvoiced = filteredInvoices.reduce(
    (sum, item) => sum + (item[sumByProp] || 0),
    0
  );
  let toBeInvoiced;

  if (percentage === 'remainder') {
    toBeInvoiced = 0;
  } else {
    toBeInvoiced =
      totalExAmount -
      totalInvoiced -
      Math.floor((totalExAmount * percentage) / 100);
  }

  if (totalInvoiced > 0)
    newInvoice.items.push({
      description: 'Amount already invoiced ex VAT',
      amount: -1 * totalInvoiced,
    });

  if (toBeInvoiced > 0) {
    newInvoice.items.push({
      description: `Amount to be invoiced ex VAT`,
      amount: -1 * toBeInvoiced,
    });
  }

  const totalExAmountFormatted = format.currency(
    costdoc.totalExAmount,
    costdoc.currency
  );
  if (percentage === 'remainder') {
    newInvoice.description =
      filteredInvoices.length === 0
        ? `This invoice is for the full payment for this order. Total amount is ${totalExAmountFormatted} ex vat. Thanks for working with Cutr.`
        : `This invoice is for the remaining payment for this order. Total amount is ${totalExAmountFormatted} ex vat. Thanks for working with Cutr.`;
  } else {
    newInvoice.description = `This invoice is for ${percentage}% payment for this order. Total amount is ${totalExAmountFormatted} ex vat. Thanks for working with Cutr.`;
  }

  return {
    ...newInvoice,
    ...CalculatedQuoteProperties(newInvoice),
  };
};

// This mocks the behaviour of the db model, which has calculated props as virtuals.
const BaseQuoteMock = function (quote) {
  return { ...quote, ...CalculatedQuoteProperties(quote) };
};

// Calculate totals
const CalculatedQuoteProperties = function (costdoc) {
  if (!costdoc.items || isNaN(costdoc.taxRate))
    throw 'items or tax rate missing';

  const sumBy = (arr, fn) => arr.reduce((sum, item) => sum + fn(item), 0);

  const totalExAmount = sumBy(costdoc.items || [], (item) =>
    item.amount ? Number(item.amount) : 0
  );

  const vatAmount = sumBy(costdoc.items || [], (item) =>
    item.amount ? Math.round((item.amount * costdoc.taxRate) / 100) : 0
  );

  return {
    totalExAmount,
    vatAmount,
    totalInAmount: totalExAmount + vatAmount,
  };
};

function quoteIsActive(quote) {
  return ['ready', 'accepted'].includes(quote.status);
}

export {
  InvoiceFromCostDoc,
  BaseQuoteMock,
  CalculatedQuoteProperties,
  CreatePaymentTerms,
  filterInvoices,
  allowedQuoteActions,
  quoteIsActive,
};
